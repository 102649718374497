<template>
  <div class="ParkDetail">
    <div class="banner">
      <img
        src="@/assets/images/parkBanner.png"
        alt=""
      >
    </div>
    <div class="breadBlock">
      <!-- <Breadcrumb
        separator=">"
        class="bread"
      >
        <BreadcrumbItem
          to="/cemeteryDetail"
          class="textColorO"
        >陵园首页</BreadcrumbItem>
        <BreadcrumbItem class="textColor">精选园区</BreadcrumbItem>
      </Breadcrumb> -->
    </div>
    <div class="main container">
      <parkList :companyId="companyId"></parkList>
    </div>
  </div>
</template>

<script>
import parkList from '../components/parkList.vue'
export default {
  data() {
    return {
      companyId: this.$route.query.companyId || 0,
    }
  },
  components: {
    parkList,
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="less" scoped>
.ParkDetail {
  .banner {
    height: 220px;
  }
  .breadBlock {
    line-height: 40px;
    .bread {
      width: 1120px;
      margin: auto;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      letter-spacing: 1px;
      .textColor {
        font-weight: 400;
        color: #299654;
      }
    }
    /deep/.ivu-breadcrumb-item-separator {
      color: #929292;
    }
  }
  .main {
    width: 1120px;
  }
}
</style>