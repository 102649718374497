<template>
  <div class="parkList">
    <div class="itemList">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        @click="routeTo('/ParkDetail', { cemeteryId: item.cemeteryId })"
      >
        <!-- @click="routeTo('/ParkDetail',{source:[{path:'/cemeteryDetail',name:'陵园首页'},{path:'/ParkList',name:'精选园区'}]})"/ -->
        <div class="item_img">
          <img :src="item.cemeteryPic" />
        </div>
        <div class="item_price">
          <!-- <span>¥{{item.}}</span>起 -->
          </div>
        <div class="item_title">{{item.cemeteryName}}</div>
        <div class="item_desc">
          {{item.cemeteryHighlights}}
        </div>
      </div>
      
    </div>
    <!-- <div class="page">
      <Row class="mb20">
          <Page
            class="mt20 mr20 fr"
            size="small"
            show-elevator
            show-sizer
            show-total
            :total="total"
            :page-size="searchForm.pageSize"
            :current.sync="searchForm.page"
            @on-change="homePageYoulikeList"
            @on-page-size-change="
              (pageSize) => {
                (searchForm.pageSize = pageSize), search();
              }
            "
          />
        </Row>
    </div> -->
  </div>
</template>

<script>
import api from "@/util/api";
export default {
  props: ["companyId"],
  data() {
    return {
      list:[],
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    search() {
      api.companyCompanyCemetery({ companyId: this.companyId }, (res) => {
        this.list = res.data
      });
    },
  },
};
</script>

<style lang="less" scoped>
.parkList {
  padding-top: 8px;
  .itemList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    .item:not(:nth-child(4n+1)){
      margin-left:10px;
    }
    .item {
      width: 271px;
      height: 399px;
      padding: 20px;
      cursor: pointer;
      margin-bottom: 12px;
      border-radius: 2px;
      background: #ffffff;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid #299654;
      }
      .item_img {
        width: 231px;
        height: 231px;
        background: #ffffff;
        border-radius: 2px;
      }
      .item_price {
        margin-top: 12px;
        font-size: 16px;
        font-weight: 400;
        color: #838383;
        line-height: 24px;
        letter-spacing: 1px;
        span {
          font-size: 24px;
          font-weight: 400;
          color: #e64747;
          letter-spacing: 1px;
          margin-right: 2px;
        }
      }
      .item_title {
        margin-top: 12px;
        font-size: 18px;
        font-weight: 600;
        color: #1a1a1a;
        line-height: 25px;
      }
      .item_desc {
        margin-top: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #838383;
        line-height: 21px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        letter-spacing: 1px;
      }
    }
  }
  .page {
    display: flex;
  }
  /deep/.ivu-page {
    margin-bottom: 40px;
    margin-left: auto;
  }
}
</style>